import cn from 'classnames'
import React from 'react'
import config from '../../config.json'
import Banner from '../banner/Banner'
import Header from '../Header/Header'
import LazyLoad from '../helpers/LazyLoad'

const Page = ({
    id,
    classNames,
    title,
    image,
    description,
    children,
    banners = true,
}) => {
    if (!title) title = config.titles.main
    if (!image) image = 'https://www.nfthive.io/nfthive-logo.png'
    if (!description) description = description = config.descriptions.main

    return (
        <div
            className={cn(
                'overflow-x-hidden h-auto min-h-screen',
                'm-auto ml-0 font-thin w-full',
                classNames,
            )}
            id={id}
        >
            <Header title={title} description={description} image={image} />
            <div>
                <LazyLoad offsetTop={100}>
                    <div
                        className={cn(
                            'flex flex-wrap justify-center hidden',
                            'w-full max-w-700px rounded-xl shadow overflow-hidden mx-auto mt-8',
                        )}
                    >
                        <img
                            src={image}
                            alt={'NFTHive Logo - WAX NFT Marketplace'}
                        />
                    </div>
                </LazyLoad>
            </div>
            {banners && <Banner />}
            {children}
        </div>
    )
}

export default Page
