import cn from 'classnames'
import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import { get } from '../helpers/Api'
import LoadingIndicator from '../loadingindicator'
import { Context } from '../waxplorer'

const Banner = (props) => {
    const [state, dispatch] = useContext(Context)

    const minute = moment().minutes()

    const counter = state.bannerCounter ? state.bannerCounter : minute

    const banners = state.banners

    const parseBanners = (res) => {
        dispatch({ type: 'SET_BANNERS', payload: res })
    }

    useEffect(() => {
        if (!banners) {
            get(`all-banners?source=nfthive`).then(parseBanners)
        }
        setTimeout(() => {
            dispatch({ type: 'SET_BANNER_COUNTER', payload: counter + 1 })
        }, 60000)
    }, [counter])

    return (
        <div
            className={cn(
                'relative flex justify-center mx-auto',
                'pb-4 2xl:pb-8',
            )}
        >
            {banners ? (
                <div
                    className={cn(
                        'w-auto grid grid-cols-1 lg:px-5',
                        {
                            'lg:grid-cols-3': banners.length > 2,
                        },
                        { 'lg:grid-cols-2': banners.length === 2 },
                        { 'lg:grid-cols-1': banners.length === 1 },
                    )}
                >
                    <a
                        href={banners[counter % banners.length]['url']}
                        target="_blank"
                        className={cn(
                            'w-0 mb-4 md:mb-0 lg:w-auto lg:px-5 w-auto md:h-28 1xl:h-28 2xl:h-40',
                        )}
                        rel="noreferrer"
                    >
                        {' '}
                        {banners[counter % banners.length]['image'].includes(
                            'video:',
                        ) ? (
                            <video
                                autoPlay={true}
                                muted={true}
                                playsInline={true}
                                loop
                                src={
                                    'https://ipfs.hivebp.io/ipfs/' +
                                    banners[counter % banners.length][
                                        'image'
                                    ].replace('video:', '')
                                }
                            />
                        ) : (
                            <img
                                src={banners[counter % banners.length]['image']}
                            />
                        )}
                    </a>
                    {banners.length > 1 && (
                        <a
                            href={
                                banners[(counter + 1) % banners.length]['url']
                            }
                            target="_blank"
                            className={cn(
                                'w-0 mb-4 md:mb-0 lg:w-auto lg:px-5 w-auto md:h-28 1xl:h-28 2xl:h-40',
                                'hidden md:block',
                            )}
                            rel="noreferrer"
                        >
                            {' '}
                            {banners[(counter + 1) % banners.length][
                                'image'
                            ].includes('video:') ? (
                                <video
                                    autoPlay={true}
                                    muted={true}
                                    playsInline={true}
                                    loop
                                    src={
                                        'https://ipfs.hivebp.io/ipfs/' +
                                        banners[(counter + 1) % banners.length][
                                            'image'
                                        ].replace('video:', '')
                                    }
                                />
                            ) : (
                                <img
                                    src={
                                        banners[(counter + 1) % banners.length][
                                            'image'
                                        ]
                                    }
                                />
                            )}
                        </a>
                    )}
                    {banners.length > 2 && (
                        <a
                            href={
                                banners[(counter + 2) % banners.length]['url']
                            }
                            target="_blank"
                            className={cn(
                                'w-0 mb-4 md:mb-0 lg:w-auto lg:px-5 w-auto md:h-28 1xl:h-28 2xl:h-40',
                                'hidden md:block',
                            )}
                            rel="noreferrer"
                        >
                            {' '}
                            {
                                <img
                                    src={
                                        banners[(counter + 2) % banners.length][
                                            'image'
                                        ]
                                    }
                                />
                            }
                        </a>
                    )}
                </div>
            ) : process.env.NEXT_PUBLIC_TESTNET === 'TRUE' ? (
                ''
            ) : (
                <LoadingIndicator />
            )}
        </div>
    )
}

export default Banner
